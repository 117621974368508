import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getCartItems, getDiscount, getOrder } from "../../reducers/cart";

function OrderPlaced({
  orderNumber,
  isPickup,
  email,
  cartlist,
  discount,
  metadata,
  type,
  distanceBasedShippingFee,
}) {
  useEffect(() => {
    pushDataLayer();
  });
  const pushDataLayer = () => {
    const {
      taxRate,
      shippingPrice: sp,
      freeShippingCap: fsc,
      isFlatFeeEnabled,
    } = metadata;
    if (window && typeof window != "undefined") {
      console.log("Pushed to Analytics");
      const subtotal = cartlist.reduce(
        (acc, { price, quantity }) => acc + price * quantity,
        0
      );

      let shipping = 0;
      if (!isPickup) {
        if (isFlatFeeEnabled) {
          if (sp === 0 && fsc === 0) shipping = 0;
          else if ((fsc === 0 && sp > 0) || (fsc > 0 && fsc > subtotal))
            shipping = sp;
        } else {
          shipping = Number(distanceBasedShippingFee)
            ? Number(distanceBasedShippingFee)
            : 0;
        }
      }

      let extraFee = cartlist.reduce(
        (acc, { quantity, isCrate }) => acc + (isCrate ? 35 * quantity : 0),
        0
      );
      const tax =
        ((subtotal - discount.amount + shipping + extraFee) * taxRate) / 100;
      const total = subtotal + tax + shipping + extraFee - discount.amount;
      const items = cartlist.map((item) => {
        return {
          item_name: item.title,

          currency: "USD",

          price: item.price,
          quantity: item.quantity,
        };
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        data: {
          currency: "USD",
          transaction_id: orderNumber,
          subtotal: subtotal,
          total: total,
          isPickup: isPickup,
          shipping: shipping,
          extraFee: extraFee,
          tax: tax,
          discount: discount,
          items,
        },
      });
    }
  };
  return (
    <div className={`icon-box-sm text-center`}>
      <span className={`icon-box-icon`}>
        <i className={"icon-check"}></i>
      </span>

      <div className="icon-box-content">
        <h3 className="icon-box-title">Thank You for your purchase</h3>
        {orderNumber && <p>Order Number: #{orderNumber}</p>}
        {!isPickup ? (
          <>
            <p className="mb-4 ">
              You will receive an email confirmation when your order is ready
              for pick up. If you have any questions, please call during regular
              business hours:{" "}
            </p>
            <div>
              <p className="lead text-black">
                Sunday: 10am to 4pm <br />
                Monday: Appointment Only* <br />
                Tuesday: Appointment Only* <br />
                Wednesday: 10am to 6pm <br />
                Thursday: 10am to 6pm <br />
                Friday: 10am to 6pm <br />
                Saturday: 10am to 4pm <br />
              </p>
            </div>
          </>
        ) : (
          <p>
            Your order confirmation and summary will be emailed to{" "}
            <b>{email}</b> shortly.
          </p>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  order: getOrder(state),
  cartlist: getCartItems(state),
  discount: getDiscount(state),
});
export default connect(mapStateToProps, null)(OrderPlaced);
