import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { deepEquals } from "../../utils";
import {
  getCartItems,
  getOrder,
  getDiscount,
  resetDiscount,
  setOrder,
  setProgress,
  getProgress,
} from "../../reducers/cart";
import { getIsAuthenticated, getUser, login } from "../../reducers/user";
import LoginInline from "./LoginInline";

const ORDERS_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/gatsby/orders`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};
class OrderInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      address: "",
      email: "",
      phone: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      guest: false,
      type: "",
      itemsLength: 0,
      showShippingMethods: false,
      isShippable: false,
      isDeliverable: false,
      isAllPickup: false,
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.addressValidator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  async componentDidMount() {
    const { customer, type, orderId } = this.props.order;
    console.log("updated", type, customer, orderId);
    const { AuthUser, isAuthenticated } = this.props;
    let showShippingMethods = false;
    if (type === "shipping" || type === "delivery") {
      showShippingMethods = true;
    }
    console.log("Remove discount", this.props.progress);
    const query = new URLSearchParams(window.location.search);
    console.log(query, query.get("step"), window.location.search);
    if (
      orderId &&
      this.props.discount.amount &&
      query.get("step") !== "payment" &&
      query.get("step") !== "success"
    )
      await this.removeDiscount();

    if (customer)
      this.setState({
        ...customer,
        itemsLength: this.props.items.length,
        type,
        showShippingMethods,
      });
    else if (isAuthenticated)
      this.setState({
        firstName: AuthUser.firstName,
        lastName: AuthUser.lastName,
        address: AuthUser.address.address,
        city: AuthUser.address.city,
        state: AuthUser.address.state,
        zip: AuthUser.address.zip,

        email: AuthUser.email,
        itemsLength: this.props.items.length,
        type,
        showShippingMethods,
      });

    this.findShippingMethodType();
    if (typeof window !== `undefined`) {
      navigate(`${window.location.pathname}?step=info`);
    }
  }

  removeDiscount = async () => {
    const { order, onProcessing, resetDiscount } = this.props;
    onProcessing(true);
    const url = `${ORDERS_BASE_URL}/${order.orderId}/discount`;
    const method = "delete";
    const headers = REQUEST_HEADERS;

    const response = await fetch(url, { method, headers });
    if (response.ok) {
      resetDiscount();
      onProcessing();
    } else {
      const err = await response.json();
      onProcessing(
        false,
        err.errors.map((error) => error.msg)
      );
      return;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!deepEquals(prevProps.order, this.props.order)) {
      console.log("order updated");
      const { customer, type } = this.props.order;
      if (this.props.isAuthenticated) {
        customer.email = this.props.AuthUser.email;
      }
      this.setState({
        ...customer,
        // isShipping
        type,
      });

      this.findShippingMethodType();
      this.props.onOrderTypeChange(type);
    }
    if (prevProps?.isAuthenticated !== this.props.isAuthenticated) {
      if (this.props.isAuthenticated) {
        this.setState({
          firstName: this.props.AuthUser.firstName,
          lastName: this.props.AuthUser.lastName,
          address: this.props.AuthUser.address.address,
          city: this.props.AuthUser.address.city,
          state: this.props.AuthUser.address.state,
          zip: this.props.AuthUser.address.zip,

          email: this.props.AuthUser.email,
        });
      }
    }

    if (prevProps.items !== this.props.items) {
      this.setState({
        itemsLength: this.props.items.length,
      });
      if (
        prevProps.type !== this.state.type ||
        prevState.type !== this.state.type
      ) {
        console.log("type changed");
        this.setState({ type: this.state.type });
      }
      if (prevState.showShippingMethods !== this.state.showShippingMethods) {
        this.setState({ showShippingMethods: this.state.showShippingMethods });
      }
      this.props.onProcessing(false);

      this.findShippingMethodType();
    }
  }

  setStateFromInput = (e) => {
    let obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(obj);
  };

  setStateFromCheckbox = (e) => {
    let obj = {};
    obj[e.target.name] = e.target.checked;
    this.setState(obj);

    if (!this.validator.fieldValid(e.target.name)) {
      this.validator.showMessages();
    }
  };

  processOrderRequest() {
    const { contact, items, order, type, onProcessing } = this.props;
    const { taxRate } = this.props.metadata;
    const { AuthUser } = this.props;

    const data = {
      customer: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        address: type === "pickup" ? contact.address : this.state.address,
        city: type === "pickup" ? contact.city : this.state.city,
        state: type === "pickup" ? contact.state : this.state.state,
        zip: type === "pickup" ? contact.zip : this.state.zip,
      },
      notes: this.state.notes,
      registeredUser: AuthUser ? AuthUser?._id : null,
      cart: items.map(({ _id, title, price, quantity, isCrate }) => ({
        productId: _id,
        title,
        price,
        quantity,
        isCrate,
      })),
      type,
      taxRate,
    };

    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/gatsby/orders`;
    if (order.orderId) url = `${url}/${order.orderId}`;
    const method = order.orderId ? "put" : "post";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
    };

    fetch(url, { method, headers, body: JSON.stringify(data) })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then(({ orderId, orderNumber, shipping, extraFee, tax }) => {
        this.props.setOrder({
          orderId,
          orderNumber,
          customer: {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
          },
          type: this.state.type,
          shipping,
          extraFee,
          tax,
        });
        this.props.setProgress("payment");
        navigate(`${window.location.pathname}?step=payment`);
        onProcessing();
      })
      .catch((err) =>
        err.json().then((body) => {
          onProcessing(
            false,
            body.errors.map((error) => error.msg)
          );
        })
      );
  }
  handleOrderSubmit = (e) => {
    e.preventDefault();
    let checkOrderShippingStatus = this.findShippingMethodType();

    const { onProcessing, type } = this.props;
    console.log(type, "type");
    let formValidated = this.validator.allValid();

    if (formValidated && !this.state.type !== "pickup") {
      formValidated = this.addressValidator.allValid();
    }
    console.log(this.state.type);

    if (formValidated) {
      onProcessing(true);
      this.setState({ isFrigiDaire: false }, () => {
        this.processOrderRequest();
      });
    } else {
      onProcessing(false, "Please fill in the required fields.");
      this.validator.showMessages();
      this.addressValidator.showMessages();
    }
  };
  showStoreLocation = () => {
    const { metadata, items } = this.props;
    const locationIds = [];
    if (metadata.storeLocation && metadata.storeLocation.length > 0) {
      metadata.storeLocation.forEach((store) => {
        items.forEach((location) => {
          if (
            location.storeLocation?._id &&
            store._id === location.storeLocation._id &&
            !locationIds.includes(location.storeLocation._id)
          )
            locationIds.push(location.storeLocation._id);
        });
      });
      return metadata.storeLocation.filter((location) =>
        locationIds.includes(location._id)
      );
    } else {
      return [];
    }
  };
  toggleGuestMode = () => {
    this.setState({
      guest: !this.state.guest,
    });
  };

  findShippingMethodType = () => {
    console.log("Method Finding");
    let isDeliverable = false;
    let isShippable = false;
    let isShipping = false;
    let isAllPickup = false;

    let onlyShippable = this.props.items.every((item) => {
      return item.isDefaultShippingEnabled || item.isShippable;
    });
    let onlyDeliverable = this.props.items.every(
      (item) => item.isDefaultShippingEnabled || item.isDeliverable
    );
    let onlyPickUp = this.props.items.every(
      (item) => item.isDefaultShippingEnabled || item.isPickup
    );
    if (onlyShippable && onlyDeliverable && onlyPickUp) {
      console.log("allow both methods");
      isShipping = false;
      isDeliverable = true;
      isShippable = true;
      isAllPickup = true;
    } else if (onlyShippable && onlyDeliverable) {
      console.log("allow both methods");
      isShipping = false;
      isDeliverable = true;
      isShippable = true;
      isAllPickup = false;
    } else if (onlyShippable && onlyPickUp) {
      console.log("allow both methods");
      isShipping = false;
      isDeliverable = false;
      isShippable = true;
      isAllPickup = true;
    } else if (onlyDeliverable && onlyPickUp) {
      console.log("allow both methods");
      isShipping = false;
      isDeliverable = true;
      isShippable = false;
      isAllPickup = true;
    } else if (onlyShippable) {
      console.log("only shipping");
      isShipping = true;
      isDeliverable = false;
      isShippable = true;
    } else if (onlyDeliverable) {
      console.log("only delivery");
      isShipping = false;
      isDeliverable = true;
      isShippable = false;
    } else if (onlyPickUp) {
      console.log("only pickup");
      isShipping = false;
      isDeliverable = false;
      isShippable = false;
      isAllPickup = true;
    } else {
      this.props.onOrderTypeChange("pickup");
      isShipping = false;
      this.props.onProcessing(
        false,
        "There are shippable and non-shippable products in cart. We can't process this order. Please remove one of them."
      );
      return false;
    }
    this.setState({
      isDeliverable,
      isShippable,
      isAllPickup,
      // showShippingMethods: isShipping,
    });
    return true;
  };
  render() {
    const { metadata, contact, onOrderTypeChange } = this.props;

    const { stripeAccount, shipTo, shipping } = metadata;
    const { guest } = this.state;
    const storeLocations = this.showStoreLocation();

    return (
      <>
        <LoginInline
          setInput={this.setStateFromInput}
          setCheckbox={this.toggleGuestMode}
          guest={this.state.guest}
        />

        <form onSubmit={this.handleOrderSubmit}>
          <h2 className="checkout-title">Shipping Details</h2>
          <div className="row">
            {guest && (
              <div className="col-sm-6">
                <label htmlFor="email">Email address *</label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  value={this.state.email}
                  onChange={this.setStateFromInput}
                />
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="firstName">First Name *</label>
              <input
                id="firstName"
                type="text"
                className="form-control"
                name="firstName"
                value={this.state.firstName}
                onChange={this.setStateFromInput}
              />
              {this.validator.message(
                "firstName",
                this.state.firstName,
                "required|alpha_space"
              )}
            </div>

            <div className="col-sm-6">
              <label htmlFor="lastName">Last Name *</label>
              <input
                id="lastName"
                type="text"
                className="form-control"
                name="lastName"
                value={this.state.lastName}
                onChange={this.setStateFromInput}
              />
              {this.validator.message(
                "lastName",
                this.state.lastName,
                "required|alpha_space"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="phone">Phone *</label>
              <input
                id="phone"
                type="tel"
                className="form-control"
                name="phone"
                value={this.state.phone}
                onChange={this.setStateFromInput}
              />
              {this.validator.message(
                "phone",
                this.state.phone,
                "required|phone"
              )}
            </div>
          </div>

          {this.validator.message(
            "Shipping Method",
            this.state.type,
            "required"
          )}
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="checkout-create"
              value={this.state.type === "pickup"}
              checked={this.state.type === "pickup"}
              disabled={!shipping.pickup.isEnabled || !this.state.isAllPickup}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  this.setState({
                    ...contact,
                    type: "pickup",
                    showShippingMethods: false,
                  });
                  onOrderTypeChange("pickup");
                } else {
                  this.setState({
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                  });
                  this.addressValidator.purgeFields();
                  this.setState({
                    ...contact,
                    type: "",
                    showShippingMethods: true,
                  });
                  onOrderTypeChange("");
                }
              }}
            />
            <label className="custom-control-label" htmlFor="checkout-create">
              {shipping.pickup.label_text ?? "Local Pickup"}{" "}
              {/* <span className="text-danger">Free</span> */}
              {!shipping.pickup.isEnabled
                ? " (Not Available)"
                : !this.state.isAllPickup
                ? " (Not Available)"
                : ""}
              <br />
              <span className="text-danger">
                {shipping.pickup.note && `Note: ${shipping.pickup.note}`}
              </span>
            </label>
          </div>

          {this.state.type === "pickup" && shipping.pickup.isEnabled && (
            <>
              {storeLocations?.length > 1 ? (
                storeLocations.map((store) => (
                  <div>
                    <span style={{ color: "#a50f20" }}>&#9737;</span>{" "}
                    {store.name}, {store.address}, {store.city}, {store.state},{" "}
                    {store.zipCode}
                  </div>
                ))
              ) : metadata.storeLocation.length > 1 &&
                metadata.storeLocation.filter((loc) => loc.isDefault).length >
                  0 ? (
                metadata.storeLocation
                  .filter((loc) => loc.isDefault)
                  .map((store) => (
                    <div key={store}>
                      <span style={{ color: "#a50f20" }}>&#9737;</span>{" "}
                      {store.name}, {store.address}, {store.city}, {store.state}
                      ,{store.zipCode}
                    </div>
                  ))
              ) : metadata.storeLocation.length === 1 ? (
                metadata.storeLocation.map((store, index) => (
                  <div key={"store-" + index}>
                    <span style={{ color: "#a50f20" }}>&#9737;</span>{" "}
                    {store.name}, {store.address}, {store.city}, {store.state},{" "}
                    {store.zipCode}
                  </div>
                ))
              ) : (
                <div>
                  <span style={{ color: "#a50f20" }}>&#9737;</span>,{" "}
                  {this.props.contact.address},{this.props.contact.state},
                  {this.props.contact.zipCode}
                </div>
              )}
            </>
          )}
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="checkout-diff-address"
              value={this.state.showShippingMethods}
              checked={this.state.showShippingMethods}
              onChange={(e) => {
                const { checked } = e.target;
                const newType =
                  this.state.type === "pickup" ? "" : this.state.type;
                if (checked) {
                  this.setState({
                    ...contact,

                    showShippingMethods: true,
                    type: newType,
                  });
                  onOrderTypeChange(newType);
                } else {
                  this.setState({
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                    type: "",
                    showShippingMethods: false,
                  });
                  // onOrderTypeChange("pickup");
                  this.addressValidator.purgeFields();
                }
                // if (this.state.isShippable && !this.state.isDeliverable)
                //   this.setState({ isShipping: true });
                // if (this.state.isDeliverable && !this.state.isShippable)
                //   this.setState({ isShipping: false });
                // onOrderTypeChange();
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="checkout-diff-address"
            >
              Shipping Method
            </label>
            {/* {this.validator.message(
              "isShipping",
              this.state.isShipping,
              "required"
            )} */}
          </div>

          {this.state.showShippingMethods && (
            <>
              <div className="d-flex flex-column  justify-content-center ">
                <div style={{ paddingLeft: "2rem" }}>
                  <div className="custom-control custom-radio ml-2 mt-0">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="checkout-delivery-type1"
                      name="isShipping"
                      checked={this.state.type === "delivery"}
                      value={this.state.type === "delivery"}
                      disabled={
                        !shipping.delivery.isEnabled ||
                        !this.state.isDeliverable
                      }
                      onChange={(e) => {
                        this.setState({ type: "delivery" });
                        onOrderTypeChange("delivery");
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkout-delivery-type1"
                    >
                      {" "}
                      {shipping.delivery.label_text ?? "Local Delivery"}
                      {!shipping.delivery.isEnabled
                        ? "  (Not Available)"
                        : !this.state.isDeliverable
                        ? "  (Not Available)"
                        : ""}
                      <br />
                      <span className="text-danger">
                        {shipping.delivery.note &&
                          `Note: ${shipping.delivery.note}`}
                      </span>
                    </label>
                  </div>
                </div>
                <div style={{ paddingLeft: "2rem" }}>
                  <div className="custom-control custom-radio ml-2  mt-0">
                    <input
                      type="radio"
                      className="custom-control-input "
                      id="checkout-delivery-type2"
                      name="isShipping"
                      checked={this.state.type === "shipping"}
                      value={this.state.type === "shipping"}
                      disabled={
                        !shipping.shipping.isEnabled || !this.state.isShippable
                      }
                      onChange={(e) => {
                        this.setState({ type: "shipping" });

                        onOrderTypeChange("shipping");
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkout-delivery-type2"
                    >
                      <span>
                        {" "}
                        {shipping.shipping.label_text ?? "Standard Shipping"}
                      </span>
                      {!shipping.shipping.isEnabled
                        ? "  (Not Available)"
                        : !this.state.isShippable
                        ? "  (Not Available)"
                        : ""}
                      <br />
                      <span className="text-danger">
                        {shipping.shipping.note &&
                          `Note: ${shipping.shipping.note}`}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <label htmlFor="address">Address *</label>
              <input
                id="address"
                type="text"
                name="address"
                className="form-control"
                value={this.state.address}
                onChange={this.setStateFromInput}
                placeholder="Street address"
              />
              {this.addressValidator.message(
                "address",
                this.state.address,
                "required|min:10|max:120"
              )}

              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="town">Town / City *</label>
                  <input
                    id="town"
                    type="text"
                    className="form-control"
                    name="city"
                    value={this.state.city}
                    onChange={this.setStateFromInput}
                  />
                  {this.addressValidator.message(
                    "city",
                    this.state.city,
                    "required|alpha_num_dash_space"
                  )}
                </div>

                <div className="col-sm-6">
                  <label htmlFor="state">State / County *</label>
                  <select
                    id="state"
                    className="form-control"
                    name="state"
                    value={this.state.state}
                    onBlur={this.setStateFromInput}
                    onChange={this.setStateFromInput}
                  >
                    <option value={""} disabled>
                      Select State
                    </option>
                    {shipTo.map((state, i) => (
                      <option key={i} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {this.addressValidator.message(
                    "state",
                    this.state.state,
                    "required"
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="zip">Postcode / ZIP *</label>
                  <input
                    id="zip"
                    type="text"
                    className="form-control"
                    name="zip"
                    value={this.state.zip}
                    onChange={this.setStateFromInput}
                  />
                  {this.addressValidator.message(
                    "zip",
                    this.state.zip,
                    "required|integer|min:5|max:5"
                  )}
                </div>
              </div>
            </>
          )}
          <label htmlFor="notes">Order notes (optional)</label>
          <textarea
            id="notes"
            className="form-control"
            cols="30"
            rows="4"
            name="notes"
            value={this.state.notes}
            onChange={this.setStateFromInput}
            placeholder="Notes about your order, e.g. special notes for delivery"
          ></textarea>

          <div className="d-flex justify-content-between">
            <Link to="/cart/" className="btn-link">
              <span className="btn-text">Return to Cart</span>
              <span className="btn-hover-text">Return to Cart</span>
            </Link>
            <button
              type="submit"
              aria-labelledby="continue"
              disabled={
                this.state.itemsLength === 0 ||
                (!this.state.isDeliverable &&
                  !this.state.isShippable &&
                  !this.state.isAllPickup)
              }
              className="btn btn-primary btn-order "
            >
              {true ? (
                <>
                  {" "}
                  <span id="continue" className="btn-text">Continue to Request</span>
                  <span className="btn-hover-text">
                    {stripeAccount
                      ? "Continue to Request"
                      : "Continue to Request"}
                  </span>
                </>
              ) : (
                <>
                  <span className="btn-text">Continue to Payment</span>
                  <span className="btn-hover-text">
                    {stripeAccount
                      ? "Continue to Payment"
                      : "Continue to Payment"}
                  </span>
                </>
              )}
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  items: getCartItems(state),
  order: getOrder(state),
  discount: getDiscount(state),
  AuthUser: getUser(state),
  progress: getProgress(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProgress: (value) => dispatch(setProgress(value)),
  setOrder: (data) => dispatch(setOrder(data)),

  resetDiscount: () => dispatch(resetDiscount()),
  login: (data) => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoForm);
