import React, { Component } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import { getIsAuthenticated, getUser, login } from "../../reducers/user";

class LoginFormInline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errors: [],
      processing: false,
    };

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.guest !== this.props.guest) {
      console.log("guest ", this.props.guest);
    }
  }

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };
  handleProcessing = (processing = false, errors = null) => {
    this.setState({ processing, errors });
  };
  handleLoginSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    this.handleProcessing(true);
    this.props
      .login({ email: this.state.email, password: this.state.password })
      .then((data) => {
        this.handleProcessing();
      })
      .catch((err) => {

        if (err.response.status === 400)
          if (err.response.data.errors) {
            this.handleProcessing(false, err.response.data.errors);
          } else if (err.response.data.error) {
            this.handleProcessing(false, [{ msg: err.response.data.error }]);
          }
        //   else if (err.response.status === 500) setOpenSnackbar(true);
      });
  };

  render() {
    const { isAuthenticated, guest } = this.props;

    return (
      <>
        <LoadingOverlay>
          <Loader text="Processing ..." loading={this.state.processing} />
          {this.state?.errors && this.state.errors.length > 0 && (
            <div className="alert alert-danger">
              {this.state.errors.map((error, index) => (
                <div className="text-white" key={index}>
                  {error.msg}
                </div>
              ))}
            </div>
          )}
          {!isAuthenticated && !guest && (
            <form onSubmit={this.handleLoginSubmit}>
              <h2 className="checkout-title">Sign in to your account</h2>
              {/* {!isAuthenticated ? "Not Logged in Login or guest" : ""} */}
              <div className="row">
                <div className="col-sm-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkout-create-acc"
                      value={this.props.guest}
                      checked={this.props.guest}
                      onChange={this.props.setCheckbox}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkout-create-acc"
                    >
                      Continue as guest?
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <label htmlFor="email2">Email address *</label>
                  <input
                    id="email2"
                    type="email"
                    className="form-control"
                    name="email2"
                    // value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </div>
                <div className="col-sm-5">
                  <label htmlFor="password">Password *</label>
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                  />
                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required|alpha_space"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-5 col-xl-4 mb-1">
                  <button
                    // onClick={this.submitForm}
                    type="submit"
                    name="submit"
                    aria-labelledby="login"
                    className="btn btn-primary btn-sm"
                  >
                    <span id="login">Login</span>
                    <i className="icon-long-arrow-right"></i>
                  </button>
                </div>
                <div className="col-sm-4 col-md-4 col-l5-6 col-xl-4">
                  <Link
                    to={"/signup/"}
                    className="btn btn-primary btn-sm align-bottom align-text-bottom"
                  >
                    Create Account
                  </Link>
                </div>
              </div>
            </form>
          )}
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  AuthUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormInline);
