import React, { useState, useEffect } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch } from "react-redux";
import { resetCart } from "../../reducers/cart";

let stripePromise;
const getStripe = (stripeAccount) => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK, { stripeAccount });
  }
  return stripePromise;
};
const Status = ({
  clientSecret,
  setProgress,
  onProcessing,
  orderNumber,
}) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    setIsLoading(true);
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setIsLoading(false);
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        setStatus(paymentIntent.status);
        console.log(paymentIntent.status,'status')
        switch (paymentIntent.status) {
          case "succeeded":
            setProgress("success");
            setMessage("Success! Payment received.");
            break;

          case "processing":
            setMessage(
              "Payment processing. We'll update you when payment is received."
            );
            dispatch(resetCart);
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setProgress("payment");
            onProcessing(
              false,
              "Payment failed. Please try another payment method."
            );
            setMessage("Payment failed. Please try another payment method.");
            break;

          default:
            onProcessing(false, "Something Went Wrong!");
            setMessage("Something went wrong.");
            break;
        }
      })
      .catch((e) => {
        console.log(e);
      });
      console.log("navigate status")
    // // navigate(`${window.location.pathname}?step=success`);
    onProcessing();
    //eslint ignore react-hooks/exhaustive-deps
  }, [stripe]);

  return (
    <LoadingOverlay>
      <Loader text="Processing ..." loading={isLoading} />

      <div className={`icon-box-sm text-center`}>
        {status === "succeeded" && (
          <span className={`icon-box-icon`}>
            <i className={"icon-check"}></i>
          </span>
        )}

        <div className="icon-box-content">
          {orderNumber !== null && <p>Order Number: #{orderNumber}</p>}
          <p className="mb-4">{message}</p>
        </div>
      </div>
    </LoadingOverlay>
  );
};
const PaymentStatus = ({
  metadata,
  progress,
  setProgress,
  onProcessing,
  orderNumber,
}) => {
  const stripePromise = getStripe(metadata.stripeAccount);
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    const secret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    setClientSecret(secret);
  }, []);
  return (
    clientSecret && (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <Status
          clientSecret={clientSecret}
          progress={progress}
          setProgress={setProgress}
          orderNumber={orderNumber}
          onProcessing={onProcessing}
        />
      </Elements>
    )
  );
};
export default PaymentStatus;
