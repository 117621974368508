import React, { Component } from "react";

import { connect } from "react-redux";

// // import Custom Components
import OrderSummary from "./OrderSummary";

import { Stepper } from "react-form-stepper";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import ErrorsContainer from "./ErrorsContainer";
import OrderInfo from "./OrderInfo";
import PaymentsForm from "./PaymentForm";
import OrderPlaced from "./OrderPlaced";

import {
  getOrder,
  getProgress,
  resetCart,
  restoreCart,
  setOrder,
  setProgress,
} from "../../reducers/cart";
import { getIsAuthenticated, getUser } from "../../reducers/user";
import PaymentStatus from "./paymentStatus";
import { navigate } from "gatsby";
const STEPPER_STYLES = {
  activeBgColor: "#C02425",
  completedBgColor: "#C02425",
  size: "4em",
  circleFontSize: "2em",
  labelFontSize: "1.2rem",
};

const STEPS = [
  {
    label: "Order Details",
    children: <i className="fa fa-map " aria-hidden="true"></i>,
  },
  {
    label: "Payment Method",
    children: <i className="fa fa-credit-card fa-10x" aria-hidden="true"></i>,
  },
  {
    label: "Success",
    children: <i className="fa fa-check fa-10x" aria-hidden="true"></i>,
  },
];

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, type: "", errors: null };

    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    if (this.props.progress === "payment") {
      this.props.setProgress("info");
    } else if (this.props.progress === "success") {
      this.props.resetCart();
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.pageLocation.search);
    console.log(query, this.props.pageLocation.search, query.get("verify"));
    if (query.get("verify")) {
      console.log("Verify");
      this.props.setProgress("verify");
      navigate(`${window.location.pathname}?step=success`);
    }
    if (query.get("id")) {
      let url = `${
        process.env.GATSBY_API_BASE_URL
      }/api/v1/gatsby/orders/checkout/${query.get("id")}`;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      };

      fetch(url, { method: "GET", headers })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((order) => {
          this.props.setProgress("info");

          const cart = order.cart.map((item) => {
            const product = this.props.products.filter(
              (p) => p._id === item.productId
            );
            if (product.length > 0) {
              return {
                ...product[0],
                stock: product[0].quantity,
                quantity: item.quantity,
                isCrate: item.isCrate,
              };
            } else return null;
          });
          if (cart.includes(null)) {
            this.handleProcessing(false, "Error: Invalid order");
          } else {
            this.props.setOrder(order);

            this.props.restoreCart(cart);
          }

          // onProcessing();
        })
        .catch((err) =>
          err.json().then((body) => {
            // onProcessing(
            //   false,
            //   body.errors.map((error) => error.msg)
            // );
          })
        );
    }
    if (this.props.order) {
      this.handleOrderType(this.props.order.type);
    }
    // const { shipTo } = this.props.metadata;
    // if (shipTo.length === 0) this.setState({ isPickup: true });
    // this.setState({ isPickup: false })
    if (typeof window !== `undefined`) {
      window.addEventListener("beforeunload", this.componentCleanup);
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  handleProcessing = (processing = false, errors = null) => {
    this.setState({ processing, errors });
  };

  handleOrderType = (value) => this.setState({ type: value });

  getActiveStep(progress) {
    switch (progress) {
      case "info":
        return 0;
      case "payment":
        return 1;
      case "success":
        return 2;
      default:
        return 0;
    }
  }

  render() {
    const { metadata, contact, order, progress } = this.props;
    return (
      <LoadingOverlay>
        <Loader text="Processing ..." loading={this.state.processing} />

        <div style={{ marginBottom: 36 }}>
          <Stepper
            steps={STEPS}
            aria-label="stepper"
            size={"4em"}
            styleConfig={STEPPER_STYLES}
            activeStep={this.getActiveStep(progress)}
          />
        </div>
        <div className="page-content">
          <div className="checkout">
            <div className="container">
              <div className="checkout row">
                <div className="col-lg-6 col-sm-12 col-xs-12">
                  {progress === "info" && (
                    <OrderInfo
                      metadata={metadata}
                      contact={contact}
                      type={this.state.type}
                      onOrderTypeChange={this.handleOrderType.bind(this)}
                      onProcessing={this.handleProcessing.bind(this)}
                    />
                  )}
                  {this.state.errors && (
                    <div className="pt-2">
                      <ErrorsContainer errors={this.state.errors} />
                    </div>
                  )}

                  {progress === "payment" && (
                    <PaymentsForm
                      metadata={metadata}
                      paypalClientId={metadata.paypalClientId}
                      onProcessing={this.handleProcessing.bind(this)}
                      progress={progress}
                    />
                  )}
                   {progress === "verify" && (
                    <PaymentStatus
                      metadata={metadata}
                      onProcessing={this.handleProcessing.bind(this)}
                      progress={progress}
                      setProgress={this.props.setProgress}
                      orderNumber={order.orderNumber}
                    />
                  )}
                  {progress === "success" && (
                    <OrderPlaced
                      orderNumber={order.orderNumber}
                      // type={order.type || "default"}
                      shipping={order.shipping}
                      metadata={metadata}
                      type={this.state.type}
                      email={order.customer?.email}
                    />
                  )}
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12 mt-5 mt-mb-0">
                  <OrderSummary
                    progress={progress}
                    metadata={metadata}
                    type={this.state.type}
                    shipping={order.shipping}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  order: getOrder(state),
  progress: getProgress(state),
  customer: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  setProgress: (value) => dispatch(setProgress(value)),
  setOrder: (value) => dispatch(setOrder(value)),
  restoreCart: (value) => dispatch(restoreCart(value)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
