import React from "react";
import { graphql } from "gatsby";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import CheckoutComponent from "../components/checkout";
let stripePromise;
const getStripe = (stripeAccount) => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK, { stripeAccount });
  }
  return stripePromise;
};
function Checkout({ data, location }) {
  const stripe = getStripe(data.metadata.stripeAccount);
  return (
    <>
      <MainLayout>
        <Seo title="Owls - Checkout" />

        <h1 className="d-none">Owls - Checkout</h1>

        <div className="main">
          <PageHeader title="Secure Checkout" icon='fa fa-lock'/>
          <Breadcrumb
            title="Checkout"
            // parent1={["Shop", "shop/sidebar/list"]}
          />
          <Elements stripe={stripe}>
            <CheckoutComponent
              pageLocation={location}
              metadata={data.metadata}
              contact={data.contact}
              // categories={data.categories}
              products={data.allProducts.nodes}
            />
          </Elements>
        </div>
      </MainLayout>
    </>
  );
}

export default Checkout;

export const query = graphql`{
  metadata {
    paypalClientId
    stripeAccount
    shipTo
    taxRate
    shippingOrigin{
      state
    }
    shipping{
      pickup{
        isEnabled
        label_text
        note
      }
      delivery{
        isEnabled
        label_text
        note
      }
      shipping{
        isEnabled
        label_text
        note


      }
    }
 
    storeLocation {
      _id
      name
      state
      city
      zipCode
      address
      isDefault
    }
  }
    allProducts {
      nodes{
        _id
      title
      quantity
      price
      oldPrice
      rating
      seoMetaData{
        slug
      }
      storeLocation {
        _id
        name
        state
        city
        zipCode
        address
      }
     
      image: thumbnail {
       
          childImageSharp {
            gatsbyImageData(width: 160, height: 175, quality: 100, layout: CONSTRAINED)
          }
      }
      thumbnail {
       
          childImageSharp {
            gatsbyImageData(width: 75, height: 75, quality: 100, layout: FIXED)
          }
      }


      }
    }
  
  contact {
    address
    city
    state
    zip
  }
}
`;
