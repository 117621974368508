import React, { useEffect } from "react";
import { connect } from "react-redux";
// import Card from "../features/accordion/card";
import { Link } from "gatsby";
import slugify from "slugify";
import { GatsbyImage } from "gatsby-plugin-image";
// import Accordion from "../features/accordion/accordion";

import {
  getCartItems,
  getDiscount,
  removeFromCart,
  getOrder,
} from "../../reducers/cart";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OrderSummary({
  cartlist,
  metadata,
  discount,
  isPickup,
  removeFromCart,
  shipping,
  progress,
  type,
  order,
}) {
  const { taxRate } = metadata;

  const [totalSave = 0, setTotalSave] = React.useState(0);
  useEffect(() => {
    const save = cartlist.reduce(
      (acc, { price, oldPrice, quantity }) =>
        acc +
        (Number(oldPrice) - Number(price) > 0
          ? Number(oldPrice) - Number(price) * quantity
          : 0),
      0
    );
    setTotalSave(save + Number(discount.amount));
  }, [cartlist, discount]);
  const subtotal = cartlist.reduce(
    (acc, { price, quantity }) => acc + price * quantity,
    0
  );

  let shippingAmount = 0;
  if (progress !== "info") {
    shippingAmount = Number(shipping);
  }
  let extraFee = cartlist.reduce(
    (acc, { quantity, isCrate }) => acc + (isCrate ? 35 * quantity : 0),
    0
  );
  const shouldChargeTax = () => {
    return (
      order.customer.state.toLowerCase() ===
      metadata.shippingOrigin.state.toLowerCase()
    );
  };
  let tax = 0;
  console.log("summary", type);
  if (progress !== "info") {
    if (type === "shipping" && !shouldChargeTax()) {
      tax = 0;
    } else {
      tax =
        ((subtotal - discount.amount + shippingAmount + extraFee) * taxRate) /
        100;
    }
  }

  const total = subtotal + tax + shippingAmount + extraFee - discount.amount;
  console.log(total, subtotal, tax, shippingAmount, extraFee, discount.amount);
  return (
    <div className="summary">
      <h3 className="summary-title">Your Order</h3>

      <table className="table table-summary text-dark">
        <thead>
          <tr>
            <th>Preview</th>
            <th>Product</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {cartlist.map((item, index) => (
            <tr key={index} className="pb-2">
              <td>
                <GatsbyImage
                  image={item.thumbnail.childImageSharp.gatsbyImageData}
                  className="m-2"
                  alt={item?.title}
                />
              </td>
              <td>
                <div className="product-details">
                  <div className="p-1">
                    <Link
                      className="text-dark cart-summary-item-title"
                      to={`/products/${
                        item.seoMetaData?.slug ||
                        slugify(item.title).toLowerCase()
                      }/`}
                    >
                      <span>{item.title}</span>
                    </Link>
                    {progress === "info" && (
                      <button
                        onClick={(e) => removeFromCart(item._id)}
                        className="btn-remove"
                        aria-label="remove"
                        title="Remove Product"
                      >
                        <i className="icon-close text-primary"></i>
                      </button>
                    )}
                  </div>

                  <div className="img-pill mr-1 ml-4 cart-summary-item-title">
                    <span style={{ fontWeight: "400" }}>
                      Qty: {item.quantity}
                    </span>
                  </div>
                </div>
              </td>

              <td>
                {" "}
                $
                {Number.parseFloat(item.quantity * item.price)
                  .toFixed(2)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </td>
            </tr>
          ))}
          <tr className="summary-subtotal text-dark">
            <td>Subtotal:</td>
            <td></td>
            <td>
              {" "}
              ${" "}
              {cartlist.length > 0
                ? Number.parseFloat(
                    cartlist.reduce(
                      (acc, { quantity, price }) => acc + quantity * price,
                      0
                    )
                  ).toFixed(2)
                : "0"}
            </td>
          </tr>
          {discount.amount !== 0 && (
            <tr>
              {" "}
              <td>Discount:</td>
              <td></td>
              <td>- $ {discount.amount.toFixed(2)}</td>
            </tr>
          )}{" "}
          <tr>
            <td>Shipping:</td>
            <td></td>

            <td> {progress !== "info" ? "$" + shipping : "-"}</td>
          </tr>
          <tr>
            <td>Tax:</td>
            <td></td>

            <td>{progress !== "info" ? "$" + tax.toFixed(2) : "-"}</td>
          </tr>
          {extraFee > 0 && (
            <tr>
              <td>Extra/Special Charges:</td>
              <td>
                Reason: <b>Crated Items</b>
              </td>

              <td>$ {extraFee.toFixed(2)}</td>
            </tr>
          )}
          <tr className="summary-total">
            <td>Total:</td>
            <td></td>
            <td>${total.toFixed(2)}</td>

            {/* <td>${ ( total + shippingPrice[ props.shipping ] ).toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</td> */}
          </tr>
          {totalSave > 0 && (
            <tr>
              <td colSpan={4} className=" bg-success text-white p-3">
                <span className="d-flex justify-content-end">
                  <LazyLoadImage
                    src={"/assets/images/money_bag.svg"}
                    width={40}
                    height={40}
                  />{" "}
                  <b className="ml-2 pt-1">
                    You saved: $ {totalSave.toFixed(2)}
                  </b>
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* <Accordion type="checkout">
        <Card title="Direct bank transfer" expanded={true}>
          Make your payment directly into our bank account. Please use your
          Order ID as the payment reference. Your order will not be shipped
          until the funds have cleared in our account.
        </Card>

        <Card title="Check payments">
          Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
        </Card>

        <Card title="Cash on delivery">
          Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit. Donec odio. Quisque volutpat mattis eros.
        </Card>

        <Card title="PayPal">
          <small className="float-right paypal-link">What is PayPal?</small>
          Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non,
          semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis
          fermentum.
        </Card>

        <Card title="Credit Card (Stripe)">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/payments-summary.png`}
            alt="payments cards"
          />
          Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit
          amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
          mattis eros. Lorem ipsum dolor sit ame.
        </Card>
      </Accordion> */}

      {/* <button  */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  cartlist: getCartItems(state),
  discount: getDiscount(state),
  order: getOrder(state),
});

export default connect(mapStateToProps, { removeFromCart })(OrderSummary);
